import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class MenuService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/menu-item`;

      }

      paginate(data = null, index=null) {
            let url = `${this.#api}`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            if (index != null)
                  url = url + '?' + "page=" + (index);
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }
      getMenuItemsByMenuId(id) {
            let url = `${this.#api}/${id}/menu`
            return apiService.get(url);
      }

      sort(menuId, data) {
            let url = `${this.#api}/${menuId}/sort`
            return apiService.post(url, data);

      }



      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
